<template>
  <Layout>
    <div>
      <div>
        <h3 v-if="user">Username: {{ user.user.name }}</h3>
      </div>
      <div>
        <h3 v-if="user">Email: {{ user.user.email }}</h3>
      </div>
      <div>
        <h3 v-if="user">Role: {{ user.user.roles[0].name }}</h3>
      </div>
      <div>
        <h3 v-if="!user">You are not logged in. Please log in!</h3>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";

export default {
  name: "Profile",
  components: { Layout },

  data() {
    const user = JSON.parse(localStorage.getItem("user"));
    console.log(user);
    return {
      user: user,
    };
  },
};
</script>